var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headTitle'),_c('img',{staticClass:"mobile-example-headImg",attrs:{"src":require("../../assets/image/mobile/mobile-product-case-banner-retail.png")}}),_vm._m(0),(_vm.hasDescription)?_c('div',{staticClass:"description"},[_c('div',{staticClass:"title1"},[_vm._v("项目介绍")]),(!_vm.showDescription)?_c('div',{staticClass:"title2",on:{"click":function($event){(_vm.showDescription = !_vm.showDescription)}}},[_vm._v("展开"),_c('i',{staticClass:"el-icon-arrow-down"})]):_vm._e(),(_vm.showDescription)?_c('div',{staticClass:"title2",on:{"click":function($event){(_vm.showDescription = !_vm.showDescription)}}},[_vm._v("收起"),_c('i',{staticClass:"el-icon-arrow-up"})]):_vm._e()]):_vm._e(),(_vm.hasDescription)?_c('div',{staticStyle:{"margin-top":"2rem","padding-bottom":"5.7rem"}},[_c('el-collapse-transition',[(_vm.showDescription)?_c('div',[_c('div',{staticClass:"description-box"})]):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"mobile-head-catalogue"},[_c('div',{class:{'title1':true,'ischecked':_vm.checkIndex==1},on:{"click":function($event){(_vm.checkIndex=1)}}},[_c('span',[_vm._v("业务痛点")])]),_c('div',{class:{'title1':true,'ischecked':_vm.checkIndex==2},on:{"click":function($event){(_vm.checkIndex=2)}}},[_c('span',[_vm._v("实施方案")])]),_c('div',{class:{'title1':true,'ischecked':_vm.checkIndex==3},on:{"click":function($event){(_vm.checkIndex=3)}}},[_c('span',[_vm._v("实施收益")])])]),((_vm.checkIndex==1))?_c('div',{staticClass:"mobile-content-box1"},[_vm._m(1),_vm._m(2),_vm._m(3)]):((_vm.checkIndex==2))?_c('div',{staticClass:"mobile-content-box1"},[_vm._m(4)]):((_vm.checkIndex==3))?_c('div',{staticClass:"mobile-content-box1",staticStyle:{"margin-bottom":"5rem"}},[_vm._m(5)]):_vm._e(),_c('mobileFoot')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-head-explain"},[_c('div',{staticClass:"mobile-head-explain-title"},[_vm._v("IT 服务门户")]),_c('div',{staticClass:"mobile-head-explain-content"},[_vm._v("某大型零售连锁企业")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ware-box1"},[_c('div',{staticClass:"title1"},[_vm._v(" 应用环境复杂 ")]),_c('div',{staticClass:"ware-box1-content"},[_vm._v(" 全国拥有逾百家门店。已投入运行的应用系统多达两位数，各个系统实现技术及基础设施的运行要求都各不相同。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ware-box1"},[_c('div',{staticClass:"title1"},[_vm._v(" 问题需迅速应对 ")]),_c('div',{staticClass:"ware-box1-content"},[_vm._v(" 运维人员数量较少，对事件的响应速度较慢，譬如关键设备故障、库存失衡和收银系统故障等。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ware-box1"},[_c('div',{staticClass:"title1"},[_vm._v(" 澄清问题 ")]),_c('div',{staticClass:"ware-box1-content"},[_vm._v(" 门店花费大量时间与运维沟通澄清问题的细节，但还是经常导致运维理解产生偏差，拖延了解决进程。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-box"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-retail-1.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("运维端及用户端")]),_c('div',{staticClass:"title2"},[_vm._v("通过以 Vticket 为平台，实现IT人员、信息系统以及内部用户的全联接。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-retail-2.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("AI智能客服")]),_c('div',{staticClass:"title2"},[_vm._v("当店员遇到问题时，可以直接通过智能客服迅速获取解决方案，减少对IT部门的依赖。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-retail-3.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("远程协助")]),_c('div',{staticClass:"title2"},[_vm._v("通过远程协助功能，IT部门迅速定位门店的各种故障原因，实时在线为门店提供精准的解决方案。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"10rem"}},[_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_vm._v("自助解决率显著提升，反馈到IT部门的系统和设备的故障率下降 33%。")]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_vm._v("问题的响应速度提高了 46%。")]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_vm._v("IT人员前往门店的频率从 周/次 缩短到了 月/次 。")]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_vm._v("建立便捷的工单流转机制来帮助IT部门、运维工程师快速有效的处理故障，降低由故障带来的损失。")])])
}]

export { render, staticRenderFns }