<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/mobile-product-case-banner-retail.png"/>
        <div class="mobile-head-explain">
          <div class="mobile-head-explain-title">IT 服务门户</div>
          <div class="mobile-head-explain-content">某大型零售连锁企业</div>
        </div>
        <div class="description" v-if="hasDescription">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;padding-bottom: 5.7rem;" v-if="hasDescription">
          <el-collapse-transition>
            <div v-if="showDescription">
              <div class="description-box"></div>
            </div>
          </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue">
          <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
          <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
          <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
          <div class="ware-box1">
            <div class="title1">
              应用环境复杂
            </div>
            <div class="ware-box1-content">
              全国拥有逾百家门店。已投入运行的应用系统多达两位数，各个系统实现技术及基础设施的运行要求都各不相同。
            </div>
          </div>
          <div class="ware-box1">
            <div class="title1">
              问题需迅速应对
            </div>
            <div class="ware-box1-content">
              运维人员数量较少，对事件的响应速度较慢，譬如关键设备故障、库存失衡和收银系统故障等。
            </div>
          </div>
          <div class="ware-box1">
            <div class="title1">
              澄清问题
            </div>
            <div class="ware-box1-content">
              门店花费大量时间与运维沟通澄清问题的细节，但还是经常导致运维理解产生偏差，拖延了解决进程。
            </div>
          </div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
          <div class="purchase-box">
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-retail-1.svg"/>
              <div>
                <div class="title1">运维端及用户端</div>
                <div class="title2">通过以 Vticket 为平台，实现IT人员、信息系统以及内部用户的全联接。</div>
              </div>
            </div>
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-retail-2.svg"/>
              <div>
                <div class="title1">AI智能客服</div>
                <div class="title2">当店员遇到问题时，可以直接通过智能客服迅速获取解决方案，减少对IT部门的依赖。</div>
              </div>
            </div>
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-retail-3.svg"/>
              <div>
                <div class="title1">远程协助</div>
                <div class="title2">通过远程协助功能，IT部门迅速定位门店的各种故障原因，实时在线为门店提供精准的解决方案。</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1" style="margin-bottom:5rem">
          <div style="margin-bottom:10rem">
            <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>自助解决率显著提升，反馈到IT部门的系统和设备的故障率下降 33%。</div>
            <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>问题的响应速度提高了 46%。</div>
            <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>IT人员前往门店的频率从 周/次 缩短到了 月/次 。</div>
            <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>建立便捷的工单流转机制来帮助IT部门、运维工程师快速有效的处理故障，降低由故障带来的损失。</div>
          </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileProdutCaseRetail',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:true,
            hasDescription: false,
        };
    },

    mounted() {
    },

    methods: {
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
    padding-bottom: 3rem;
    .title2{
        // position: absolute;
        // width: 4rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #005395;
        line-height: 2.8rem;
        margin: 1.9rem 0 0 3rem;
        cursor: pointer;
        .line{
            width: 6rem;
            height: 2rem;
            border-bottom: 3px solid #005395;
            border-radius: 50%;
            margin: -1rem 0 0 0rem;
        }
    }
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    min-height: 10rem;
    padding: 3rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    flex-direction: column;
    text-align: justify;
    .title1{
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem;
    }
    .ware-box1-content {
      margin-top: 1.2rem;
      font-size: 2.2rem;
      font-family: 'CN_Regular';
      font-weight: 400;
      color: #999999;
      line-height: 3rem;
    }
}
.ware-box2{
    width: 66.8rem;
    height: 20rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem;
        margin: auto;
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: 43.5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 100%;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;

    .card{
        width: 52.8rem;
        min-height: 30rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 4rem;

            margin: 2rem 0 0 2.1rem;
        }
        .title2{
            width: 43.2rem;
            height: 7.2rem;
            font-size: 2.5rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 4rem;

            margin: 2rem 0 0 2.1rem;
        }
    }
}
.purchase-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;

    margin-top: 2rem;
}
.purchase-doit{
    color: #005395;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;

    margin: 1rem 0 0 3.5rem;
}
</style>
